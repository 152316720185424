.app-item{
    .icon{
        display: inline-block;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background-size: cover;
        background-position: 50% 50%;
        &.home{
            background-image: url('https://img.cxkoo.com/chengxuan/3/2021-10-04/37edf9b2e59f241047fe6044572fc3ad.png');
        }
        &.dashboard{
            background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-04/37edf9b2e59f241047fe6044572fc3ad.png");
        }
        &.store{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-28/2ec8985e64090eb882954ca70d4727e6.png");
        }
        &.item{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-28/d73895d995f8a2dbb054127fec96d5ec.png");
        }
        &.stock{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-28/07bda2e645387713386965711ba51fd2.png");
        }
        &.user{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-28/dcc7e21eef947ea01e554ba5b16cf2ad.png");
        }
        &.system{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-28/82ef3b7da67a31c0cdf2b7e27090923e.png");
        }
        &.order{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-29/3f7cbb33f73419f24a4cb2b4cc7ecc8a.png");
        }
        &.application{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-28/8972213da74387213a393ca08f3f277d.png");
        }
        &.finance{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-29/aaf030e5df62d4d8143f3e7211159e5b.png");
        }

    }
    &.active{
        .icon{
            &.home{
                background-image: url('https://img.cxkoo.com/chengxuan/3/2021-10-04/f4caf2121b2890d4b18d2c5a1d5c56f2.png');
            }
            &.dashboard{
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-04/f4caf2121b2890d4b18d2c5a1d5c56f2.png");
            }
            &.store{
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-04/1ddf529cf9bcbe05acf3130ce3fcfd6f.png");
            }
            &.item{
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-04/b476b0922fa3cd5ce26bb22911194e18.png");
            }
            &.stock{
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-04/9036d21344dafa3e514f559e78a31192.png");
            }
            &.user{
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-04/487ef2ac5732146f4616abaf30d49f71.png");
            }
            &.system{
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-04/2f124cdbcc36cc2ca6325d8855a32f26.png");
            }
            &.order{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-29/b0c8335bed8492e1fed4baa42d72c0f0.png");
            }
            &.application{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-08/98fac3495d539b3fe1cc2edc88478076.png");
            }
            &.finance{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-29/d02ff353d93d18db279f63abcd523098.png");
            }




        }
    }
}
.cx-second-menu-icon{
    font-size: 18px !important;
}
[class^="gicon-"], [class*=" gicon-"] {
    display:inline-block;
    width:14px;
    height:14px;
    margin-right:.3em;
    line-height:14px;
    vertical-align:-2px;
    background-image:url("https://img.cxkoo.com/chengxuan/1/2021-11-26/8802ee2dd7f12e30693f3236a77f6798.png");
    background-position:14px 14px;
    background-repeat:no-repeat
}
.gicon-arrow-up {
    background-position:-289px -96px
}
.gicon-arrow-down {
    background-position:-312px -96px
}
