
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-upload {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    outline: 0;
}
.ant-upload p {
    margin: 0;
}
.ant-upload-btn {
    display: block;
    width: 100%;
    outline: none;
}
.ant-upload input[type='file'] {
    cursor: pointer;
}
.ant-upload.ant-upload-select {
    display: inline-block;
}
.ant-upload.ant-upload-disabled {
    cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
    display: table;
    float: left;
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
}
.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #1890ff;
}
.ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
    padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #40a9ff;
    font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(0, 0, 0, 0.25);
    font-size: 30px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
    color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
    zoom: 1;
    display: inline-block;
    width: 100%;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
    display: table;
    content: '';
}
.ant-upload-picture-card-wrapper::after {
    clear: both;
}
.ant-upload-list {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    zoom: 1;
}
.ant-upload-list::before,
.ant-upload-list::after {
    display: table;
    content: '';
}
.ant-upload-list::after {
    clear: both;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
    padding-right: 28px;
}
.ant-upload-list-item {
    position: relative;
    height: 22px;
    margin-top: 8px;
    font-size: 14px;
}
.ant-upload-list-item-name {
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px;
}
.ant-upload-list-item-card-actions {
    position: absolute;
    right: 0;
    opacity: 0;
}
.ant-upload-list-item-card-actions.picture {
    top: 25px;
    line-height: 1;
    opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
    padding-right: 6px;
    color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
    height: 100%;
    padding: 0 12px 0 4px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
    display: block;
    width: 100%;
    height: 100%;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
    position: absolute;
    top: 5px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}
.ant-upload-list-item .anticon-close {
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    position: absolute;
    top: 6px;
    right: 4px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 0;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
:root .ant-upload-list-item .anticon-close {
    font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
    color: rgba(0, 0, 0, 0.65);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #e6f7ff;
}
.ant-upload-list-item:hover .anticon-close {
    opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions {
    opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
    color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions {
    opacity: 1;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    color: #f5222d;
}
.ant-upload-list-item-progress {
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding-left: 26px;
    font-size: 14px;
    line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
    background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
    border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 48px;
    height: 48px;
    font-size: 26px;
    line-height: 54px;
    text-align: center;
    opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
    max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    overflow: hidden;
    line-height: 44px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 18px;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
    padding-right: 36px;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
    line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 14px;
    width: calc(100% - 24px);
    margin-top: 0;
    padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    opacity: 1;
}
.ant-upload-list-picture-card.ant-upload-list::after {
    display: none;
}
.ant-upload-list-picture-card-container {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
    opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
    color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
    opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
    display: none;
    margin: 8px 0 0;
    padding: 0;
    line-height: 1.5;
    text-align: center;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
    position: absolute;
    bottom: 10px;
    display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
    display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
    margin-top: 18px;
    color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 32px;
    padding-left: 0;
}
.ant-upload-list .ant-upload-success-icon {
    color: #52c41a;
    font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
    -webkit-animation-name: uploadAnimateIn;
    animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
    -webkit-animation-name: uploadAnimateOut;
    animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
    -webkit-animation-name: uploadAnimateInlineIn;
    animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
    -webkit-animation-name: uploadAnimateInlineOut;
    animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateIn {
    from {
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
@keyframes uploadAnimateIn {
    from {
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
@-webkit-keyframes uploadAnimateOut {
    to {
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
@keyframes uploadAnimateOut {
    to {
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
@-webkit-keyframes uploadAnimateInlineIn {
    from {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
@keyframes uploadAnimateInlineIn {
    from {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
@-webkit-keyframes uploadAnimateInlineOut {
    to {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
@keyframes uploadAnimateInlineOut {
    to {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}
