@import "../mixins.scss";
.data-statistic{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.1);
    padding: 15px 30px;
}
.data-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 21px;
    .fi-title{
        font-size: 15px;
        color: #000;
        font-weight: bold;
    }
    .fi-sub-title{
        font-size: 15px;
        color: #858B9C;
        flex-shrink: 0;
    }
}
.data-items-cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .data-item{
        .item-num{
            font-size: 20px;
            line-height: 34px;
            color: #000;
            margin-bottom: 13px;
            &.primary{
                color: $BaseColor;
            }
        }
        .item-txt{
            font-size: 13px;
            line-height: 17px;
            color: #858B9C;
            position: relative;
            &.arrow{
                cursor: pointer;
                box-sizing: border-box;
                padding-right: 10px;
                &::after{
                    display: block;
                    content: '';
                    width: 5px;
                    height: 9px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/06e909a6b9e0cf876e19b67b009f3ca3.png");
                    background-size: cover;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    margin-top: -5px;
                }
            }
        }
    }
}
.data-chart-cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left{
        display: flex;
        flex: 1;
        flex-direction: column;
        box-sizing: border-box;
        padding-right: 20px;
        .left-top{
            .data-chart-item{
                .txt1{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 13px;
                    color: #000;
                    line-height: 17px;
                    .question{
                        width: 13px;
                        height: 13px;
                        cursor: pointer;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/f748db0ced5db54faeed21e5b220df52.png");
                        background-size: cover;
                        background-position: 50%;
                    }
                }
                .chart-num{
                    font-size: 22px;
                    line-height: 34px;
                    margin-top: 5px;
                    margin-bottom: 2px;
                    font-weight: bold;
                    color: $BaseColor;

                }
                .txt2{
                    font-size: 13px;
                    color: #858B9C;
                }
            }

        }
        .left-chart{
            width: 100%;
            height: 320px;
        }
    }
    .right{
        display: flex;
        flex: 1;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 20px;
        .right-items-cont{
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            justify-content: flex-start;
            align-items: center;
            .data-chart-item{
                display: flex;
                flex: 1;
                flex-direction: column;
                min-width: 33%;
                margin-bottom: 60px;
                .txt1{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 13px;
                    color: #000;
                    line-height: 17px;
                    .question{
                        width: 13px;
                        height: 13px;
                        cursor: pointer;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/f748db0ced5db54faeed21e5b220df52.png");
                        background-size: cover;
                        background-position: 50%;
                    }
                }
                .chart-num{
                    font-size: 22px;
                    line-height: 34px;
                    margin-top: 5px;
                    margin-bottom: 2px;
                    font-weight: bold;
                    color: $BaseColor;

                }
                .txt2{
                    font-size: 13px;
                    color: #858B9C;
                }
            }

        }
    }
}
.data-app-cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 15px;
    margin-top: 30px;
    .app-item{
        display: flex;
        flex: 1;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img{
            height: 30px;
            object-fit: contain;
            margin-bottom: 20px;
        }
        .txt{
            font-size: 13px;
            color: #000;
            line-height: 17px;
        }

    }
}
