@import "@/assets/css/mixins.scss";
.imgPicker-content{
    position: relative;
    overflow: auto;
    .panel-wrap{
        .panel-title{
           padding-bottom: 15px;
            .file-input{
                position: absolute;
                top: 0;
                left: 10px;
                right: 0;
                bottom: 0;
                width: 80px;
                height: 30px;
                direction: rtl;
                font-size: 23px;
                opacity: 0;
                cursor: pointer;
                z-index: 1;
            }
        }
        .panel-ctx{
            position: relative;
            padding: 0;
            height: 530px;
            overflow: hidden;
            .cate-list{
                width: 200px;
                height: 100%;
                overflow-y: auto;
                border: 1px solid #eee;
                box-sizing: border-box;
                .tree{
                    width: 100%;
                    display: flex;
                    flex: 1;
                    .sub-title{
                        margin-left: 5px;
                        font-size: 14px;
                        color: #c3c3c3;
                    }
                }
            }
            .cate-title{
                width: 100%;
                box-sizing: border-box;
                padding: 0px 10px;
                background-color: #eee;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                color: #333;
            }
            .img-box{
                width: calc(100% - 200px);
                height: 100%;
                padding: 15px;
                border: 1px solid #eee;
                border-left: none;
                border-radius: 0px 4px 4px 0px;
                position: relative;
            }
            .img-list{
                height: 456px;
                overflow: auto;
            }
            .img-item{
                position: relative;
                float: left;
                width: 110px;
                height: 144px;
                margin-right: 20px;
                margin-bottom: 8px;
                cursor: pointer;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                .img-wrap{
                    width: 110px;
                    height: 110px;
                    position: relative;
                    background-color: #eee;
                    border: 1px solid #eee;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                    &:hover{
                        border: 1px solid $BaseColor;
                    }
                    &:hover .img-meta{
                        display: flex;
                    }
                    .img-meta{
                        position: absolute;
                        width: 100%;
                        height: 25px;
                        font-size: 12px;
                        line-height: 25px;
                        color: #fff;
                        text-align: center;
                        background: rgba(0,0,0,.5);
                        bottom: 0;
                        display: none;
                        justify-content: space-around;
                        div{
                            &:hover{
                                color:$BaseColor;
                            }
                        }
                    }
                }
                .imgSize{
                    width: 100%;
                    font-size: 12px;
                    color:#c0c0c0;
                    text-align: center;
                    line-height: 20px;
                }
                .img-title{
                    width: 100%;
                    height: 22px;
                    font-size: 12px;
                    padding-bottom: 1px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: center;
                    position: relative;
                    line-height: 15px;
                    span{
                        display: none;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left:0;
                        bottom:0;
                        background-color: #ccc;
                    }
                    .img-name-edit{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background-color: #fff;
                        a{
                            background: #ff5370;
                            color: #fff;
                            padding: 2px 4px;
                            border-radius: 2px;
                        }
                    }
                }
                .img-title:hover{
                    span{
                        display: block;
                    }
                }
                .img-wrap.selected{
                    border: 1px solid $BaseColor;
                    i{
                        position: absolute !important;
                        right: 1px;
                        top: 1px;
                        z-index: 2;
                        color: #fff;
                        width: 12px;
                        height: 12px;
                        background: url("https://img.cxkoo.com/chengxuan/3/2021-10-02/de2ceea983ec05a571bc60eb8ef77852.png") center no-repeat;
                        background-size: cover;
                    }
                }
                .img-wrap.selected:after{
                    position: absolute;
                    display: block;
                    content: " ";
                    right: 0;
                    top: 0;
                    border: 14px solid $BaseColor;
                    border-left-color: transparent;
                    border-bottom-color: transparent;
                    z-index: 1;
                }

            }
            .img-item:nth-child(5n){
                margin-right: 0;
            }
            .pagination{
                height: 42px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
            .loading-wrap{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                z-index: 10;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: rgba(255,255,255,0.6);
                box-sizing: border-box;
                padding-left: 200px;

            }
            .noData{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #333;
            }
        }
    }
}
