/* 路由过渡效果 */
@import "./mixins.scss";
@import "./common/layout.scss";
@import "./common/table.scss";



.shadow-radius {
    border-top: 1px solid $borderColor;
    border-radius: 2px;
    padding: 15px;
    background-color: #fff !important;
    min-height: 90vh;
    vertical-align: top;
    overflow: hidden;
    position: relative;
}
.dashboard-radius{
    border-top: 1px solid $borderColor;
    border-radius: 2px;
    padding: 15px;
    min-height: 90vh;
    vertical-align: top;
    overflow: hidden;
    position: relative;
}


.noPadding {
    padding: 0px !important;
}

.hasMargin {
    margin: 0px 20px;
}
.txtCenter{
    text-align: center;
}
.txtLeft{
    text-align: left;
}
.txtRight{
    text-align: right;
}


.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: $BaseColor;
}

.link-button:hover,
.link-button:focus {
    text-decoration: none;
}
.fi-help-text{
    display: block;
    margin-top: 5px;
    color: #999;
    font-size: 12px;
    line-height: 20px;
}
.fi-link{
    cursor: pointer;
    color: $BaseColor;
}
.colorRed{
    color: $redColor !important;
}
.colorGreen{
    color: $greenColor !important;
}
.colorYellow{
    color: $yellowColor !important;
}
.btn-row{
    width: 100%;
    margin: auto;
    text-align: left;
    button:not(.ant-btn-link){
        margin: 3px;
    }
}


.content-head {
    width: 100%;
    padding: 10px;
    padding-bottom: 0px;
    box-sizing: border-box;
    background-color: #fafafa;
    //border: 1px solid $borderColor;
    .ant-form-item {
        margin-bottom: 15px;
    }

}

.search-form {
    .ant-form-item {
        display: flex;
    }

    //.ant-form-item-control-wrapper {
    //    flex: 1;
    //}
}

.serarch-btns {
    display: flex;

    .ant-form-item {
        margin-right: 10px;
    }
}

.btn-group {
    button {
        margin-left: 10px;

    }
}

.form-group {
    .ant-input, .ant-select {
        margin-left: 10px;

        &:first-child {
            margin-left: 0px;
        }
    }
}

.model-form {
    .ant-form-item {
        margin-bottom: 15px;
    }
}
.diy-form{
    .ant-form-item {
        margin-bottom: 20px;
    }
}

.content-body {
    border: 1px solid #e2e2e2;
    border-bottom: 0px;
}

.content-msg {
    width: 100%;
    border: 1px solid #ffdda6;
    background: #fff2db;
    padding: 10px;
    border-radius: 2px;

    p {
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 20px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}



.msg-title{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    background-color: #f5f5f5;
}
.fi-title{
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 25px;
    color: #333;
    font-weight: bold;
    position: relative;
    &::before{
        display: block;
        content: '';
        width: 3px;
        height: 15px;
        background-color: $BaseColor;
        position: absolute;
        left: 0px;
        top: 6px;
    }
}
.help-tip{
    cursor: pointer;
    font-size: 14px;
    color: $BaseColor;
    &:hover{
        color: $BaseColor;
    }
}
.label-title{
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    padding-left: 10px;
    line-height: 26px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:before{
        display: block;
        content: '';
        width: 3px;
        height: 18px;
        background-color: $BaseColor;
        position: absolute;
        left: 0px;
        top: 4px;
    }
}
.app-title{
    font-size: 26px;
    line-height: 36px;
    color: #000;
    font-weight: bold;
}
.icon-male{
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 12px;
    vertical-align: middle;
    background: url(https://img.cxkoo.com/chengxuan/1/2021-11-10/11fee7316b9e2c7e5541dd8305db992d.png) no-repeat;
    background-position: 50%;
    margin-left: 3px;
    background-size: cover;
}
.icon-female{
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 12px;
    vertical-align: middle;
    background: url(https://img.cxkoo.com/chengxuan/1/2021-11-10/5ab4a63d5a78f9839dddc527e514a41f.png) no-repeat;
    background-position: 50%;
    margin-left: 3px;
    background-size: cover;
}

/**
 ** 上传图片
 */
.img-add{
    width: 60px;
    height: 60px;
    background-color: #fff;
    padding: 1px;
    margin: 0 10px 5px 0;
    position: relative;
    //overflow: hidden;
    text-align: center;
    cursor: pointer;
    border: 1px dashed #999;
    color: #999;
    font: 700 46px/60px arial;
    float: left;
    img{
        width: 100%;
        max-height: 60px;
        object-fit: cover;
        vertical-align: top;
    }
    &.x-large{
        width: 120px;
        height: 170px;
        font: 700 46px/170px arial;
    }
    &.x-m{
        width: 45px;
        height: 45px;
        font: 700 30px/45px arial;
    }
    .img-del{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 10;
        font-size: 16px;
        color: #fff;
        display: none;
    }
    &:hover{
        .img-del{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .delete-icon{
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        font-size: 16px;
        top: -8px;
        right: -8px;
        z-index: 1;
    }
    &[disabled]{
        filter: grayscale(0.7);
        opacity: 0.7;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.img-list-btndel,.img-move {
    display: none;
    position: absolute;
    top: 50%
}

.img-move {
    width: 16px;
    height: 16px;
    z-index: 2;
    margin-top: -6px;
    background-image: url(https://img.cxkoo.com/chengxuan/1/2022-01-26/eb6e0c4fa699db5d7f7f77962c6a5dc2.png);
    background-repeat: no-repeat;
    background-size: 30px auto
}

.img-move-left {
    left: 0;
    background-position: 0 0
}

.img-move-right {
    background-position: right 0;
    right: 0
}

.img-list-cimg li.selected .img-list-overlay,.img-list-cimg li.selected .img-list-overlay-check,.img-list-cimg li:hover .img-list-btndel,.img-list-cimg li:hover .img-move,.img-list li.selected .img-list-overlay,.img-list li.selected .img-list-overlay-check,.img-list li:hover .img-list-btndel,.img-list li:hover, .img-add:hover .img-move {
    display: block
}




/**
 ** 富文本
 */
.edui-editor div{
    line-height: initial;
}

/**
 ** 抽屉
 */
.drawer-cont{
    .ant-form-item-label > label{
        font-weight: bold;
    }
}

/**
 ** divider
 */
.ant-divider, .ant-divider-vertical{
    margin: 0 5px;
}

/**
 ** 字数限制
 */
.ant-input-affix-wrapper .ant-input-suffix{
    font-size: 12px;
    color: #999;
}

/**
 ** 卡片tabs重写
 */
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    border-radius: 0px;
    border-left: 0px;
    margin-right: 0px;
    &:first-child{
        border-left:1px solid #e8e8e8;
    }
}

/**
 ** 背景色
 */
.ant-layout{
    background-color: #f5f5f5;
}

/**
 ** 开关组
 */
.sysPanel {
    position: relative;
    padding: 10px 20px;
    background: #f8f8f8;
    margin-bottom: 10px
}

.sysPanel .sysPanel-con {
    width: 800px
}

.sysPanel .sysPanel-con .sysPanel-title {
    font-size: 12px;
    font-weight: 700;
    color: #333;
    padding-bottom: 10px
}

.sysPanel .sysPanel-con .sysPanel-tip {
    font-size: 12px;
    color: #666;
    line-height: 1.6em
}
.list-switch {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}
/**
 ** 上传
 */
.ant-upload-picture-card-wrapper{
    display: block;
}
.ant-upload.ant-upload-select-picture-card{
    width: 95px;
    height: 95px;
}

/**
 ** 照片墙
 */
.ant-upload-list-picture-card-container{
    width: 95px;
    height: 95px;
}
.ant-upload-list-picture-card .ant-upload-list-item{
    width: 95px;
    height: 95px;
    cursor: move;
}

/**
 ** 限制字数输入框
 */
.ant-input-affix-wrapper .ant-input:not(:last-child){
    padding-right: 42px;
}
