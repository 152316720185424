$loginBaseColor:#5E4CFF;

.cx-common-login{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-image: url('https://img.cxkoo.com/chengxuan/3/2021-10-02/ff0f2407381e4c070b3d55395243dedc.jpg');
    background-size: cover;
    background-position: 50% 50%;
    min-width: 1400px;
    .login-logo{
        width: 159px;
        position: absolute;
        left: 6%;
        top: 5.3%;
        img{
            width: 100%;
        }
    }
    .cx-login-slogan{
        position: absolute;
        left: 6%;
        top: 16.6%;
        .txt1{
            font-size: 53px;
            font-weight: bold;
            color: #2C2C2C;
            line-height: 60px;
            margin-bottom: 30px;
        }
        .txt2{
            font-size: 33px;
            color: #2C2C2C;
            line-height: 40px;
            .txt-color{
                color: $loginBaseColor;
            }
        }
    }
    .cx-login-wrap{
        width: 507px;
        background-color: #fff;
        box-sizing: border-box;
        position: absolute;
        top: 16%;
        right: 11.1%;
        box-shadow: 0 2px 10px rgba(0,0,0,0.1);
        padding: 35px;
        &.register,&.forget{
            top: 12%;
        }
    }
    .cx-login-inner{
        box-sizing: border-box;
        padding-top: 25px;
        padding-bottom: 30px;
        .title{
            font-size: 32px;
            color: #2C2C2C;
            line-height: 36px;
            font-weight: bold;
            letter-spacing: 2px;
        }
        .form-cont{
            .form-item{
                width: 100%;
                box-sizing: border-box;
                height: 78px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                border-bottom: 1px solid #E0E0E0;
            }
            .fi-name{
                width: 90px;
                flex-shrink: 0;
                font-size: 20px;
                color: #333;
                box-sizing: border-box;
                padding-left: 5px;
                text-align: right;
                line-height: 32px;
                padding-bottom: 10px;
            }
            .form-control{
                padding-bottom: 10px;
                padding-left: 40px;
                position: relative;
                display: flex;
                flex: 1;
            }
            .form-input{
                border:0px;
                outline: none;
                box-shadow: none;
                font-size: 17px;
                color: #333;
                &:focus{
                    border:0px;
                    outline: none;
                }
            }
            .verify{
                height: 32px;
                cursor: pointer;
            }
            .input-type-img{
                height: 32px;
                cursor: pointer;
                line-height: 32px;
                .eye-open{
                    width: 22px;
                }
                .eye-close{
                    width: 22px;
                }
            }
        }
        .form-btn-group{
            width: 100%;
            margin-top: 33px;
            .btn-login{
                width: 100%;
                height: 60px;
                font-size: 25px;
                color: #fff;
                box-sizing: border-box;
                background-color: $loginBaseColor;
                border-radius: 2px;
                border-color: $loginBaseColor;
            }
        }
        .btn-sms{
            background-color: $loginBaseColor;
            border-color: $loginBaseColor;
        }
        .btn-sms[disabled]{
            background-color: #f5f5f5;
            border-color: #d9d9d9;
        }
        .form-actions{
            width: 100%;
            box-sizing: border-box;
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .login-check{

            }
            .left{
                font-size: 17px;
                color: #999;
            }

            .right{
                font-size: 17px;
                color: $loginBaseColor;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .txt{
                    cursor: pointer;
                    margin-right: 25px;
                    &:last-child{
                        margin-right: 0px;

                    }
                }
                .tip-txt{
                    color: #B7B7B7;
                    margin-right: 5px;
                }
            }

        }
    }
}
